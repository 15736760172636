/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from '~helpers/getters';
import { Context } from '~context/MainProvider';
import Sticky from '~molecules/sticky';
import { setCookie, getCookie } from '~helpers/cookies';
import styles from './cookie-consent.module.scss';

const CookieConsent = ({
  cookieName = 'CookieConsent',
  showOnScroll = true,
  location = 'bottom',
}) => {
  const { translations, setCookieAccepted, cookieAccepted } = useContext(Context) || {};

  useEffect(() => {
    const getCk = getCookie(cookieName) === 'true' || false;
    setCookieAccepted(getCk);
  }, []);

  // when user accepts
  const handleAccept = () => {
    setCookie(cookieName, true, 365, '/');
    setCookieAccepted(true);
  };

  return (
    <>
      {!cookieAccepted && (
        <Sticky showOnScroll={showOnScroll} className={styles.cookieContainer} position={location}>
          <>
            <span className={styles.cookieTitle}>{translate(translations, 'cookie_header', 'Cookies')}</span>
            <p className={styles.cookieDesc}>
              {translate(
                translations,
                'cookie_text',
                `We use cookies to provide a better user experience. Some cookies are necessary for our
            website to function, and others may be used for analytics and marketing. You may read
            more about the cookies we use Cookie Policy.`
              )}
            </p>
            <button onClick={() => handleAccept()} className={styles.cookieBtn}>
              {translate(translations, 'cookie_btn', `Accept & Close`)}
            </button>
          </>
        </Sticky>
      )}
    </>
  );
};

CookieConsent.propTypes = {
  acceptText: PropTypes.string,
  settingsCookie: PropTypes.bool,
  location: PropTypes.oneOf(['bottom', 'top']),
  type: PropTypes.string,
  cookieName: PropTypes.string,
  showOnScroll: PropTypes.bool,
  isPageHomepage: PropTypes.bool,
  cookieImage: PropTypes.string,
  logo: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.any]),
};

export default CookieConsent;
